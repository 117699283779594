import { useStaticQuery, graphql } from 'gatsby'
export const useDownloadContentData = lang => {
  const { posts } = useStaticQuery(
    graphql`
      query {
        posts: allWordpressPost(
          filter: { polylang_current_lang: { eq: "en_US" } }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              polylang_translations {
                polylang_current_lang
                wordpress_id
                title
                slug
                categories {
                  name
                }
                date(formatString: "MMMM DD, YYYY")
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1050, quality: 85) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
              id
            }
          }
        }
      }
    `
  )
  return {
    posts,
  }
}
